import { StyledEngineProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import i18n from 'i18n';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'react-multi-email/dist/style.css';

import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './store';
import 'video-react/dist/video-react.css';
import 'swiper/swiper.min.css';
import 'swiper/css/effect-fade';
import * as Sentry from '@sentry/react';

const isProduct = import.meta.env.VITE_APP_PROD === 'true';

Sentry.init({
    dsn: 'https://4d6c83f5a73f965c14811e06d759531e@logging.recruitgenius.ai/5',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

//Load LogRocket module

ReactDOM.render(
    <StrictMode>
        <HelmetProvider>
            <I18nextProvider i18n={i18n}>
                <ReduxProvider store={store}>
                    <StyledEngineProvider injectFirst>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </LocalizationProvider>
                    </StyledEngineProvider>
                </ReduxProvider>
            </I18nextProvider>
        </HelmetProvider>
    </StrictMode>,
    document.getElementById('root')
);
